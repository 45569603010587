<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <v-row>
        <v-col>
          <h1>Reporte Emisiones Fallidas Hogar</h1>
        </v-col>
      </v-row>
      <v-row style="padding-left: 30px; margin-top: 25px"> </v-row>
      <v-row class="mb-15" style="padding-left: 30px">
        <v-col md="3">
          <h3>Selecciona el rango de fechas</h3>
          <v-date-picker
            style="width: 100%"
            v-model="dates"
            range
          ></v-date-picker>
          <v-btn
            rounded
            color="primary"
            style="width: 100%"
            dark
            @click="consultar"
            >Consultar</v-btn
          >
          <!-- <v-btn
            rounded
            color="primary"
            style="width:100%;margin-top:20px;"
            :disabled="bloquear"
          >
            <download-csv
              :data="reportCompleteDowload"
              name="Informe_bradescard.csv"
            >
              Descargar Información
            </download-csv>
          </v-btn> -->
          <v-btn
            rounded
            color="primary"
            style="width:100%;margin-top:20px;"
            :disabled="bloquear"
            v-show="
              currentRol == 'ADMIN' ||
                currentRol == 'ADMINCC' ||
                currentRol == 'AUDITOR' ||
                currentRol == 'MANAGERCC'
            "
          >
            <download-csv
              :data="reportDownloadIt"
              name="Informe_bradescard.csv"
            >
              Descargar Informe TI
            </download-csv>
          </v-btn>
        </v-col>
        <v-col cols="9" md="9">
          <div>
            <div v-if="loading" style="text-align: center">
              <h2 clas="pb-4">Cargando Reporte....</h2>
              <br /><br />
              <md-progress-spinner
                :md-diameter="100"
                :md-stroke="10"
                md-mode="indeterminate"
              ></md-progress-spinner>
            </div>
          </div>

          <v-data-table
            :headers="columns"
            :items="leads"
            item-key="name"
            class="elevation-1"
            :search="search"
            :custom-filter="filterOnlyCapsText"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Buscar"
                class="mx-4"
              ></v-text-field>
            </template>

            <template v-slot:item.urlPoliza="{ item }">
              <v-btn
                :href="
                  'https://segurify-data.s3.amazonaws.com/' + item.urlPoliza
                "
                >Descargar</v-btn
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
var moment = require("moment-timezone");

export default {
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem("agenteUuid"),
      loading: false,
      reportType: "lead",
      leads: [],
      search: "",
      reportDownloadIt: [],
      reportCompleteDowload: [],
      bloquear: true,
      block: true,
      canal: null,
      canales: [
        { id: 1, name: "AGENTES" },
        { id: 2, name: "MASIVOS" },
        { id: 3, name: "DIRECTOS" },
      ],

      columns: [
        { text: "Poliza", value: "polizaInterna" },
        { text: "Cliente", value: "cliente" },
        { text: "Fecha inicio vigencia", value: "fecha_inicio_vigencia" },
        { text: "Fecha fin vigencia", value: "fecha_fin_vigencia" },
        { text: "Tipo de contratante", value: "tipo_contratante" },
        { text: "Plan", value: "plan" },
        { text: "Suma asegurada", value: "suma_asegurada" },
        { text: "Prima neta", value: "prima_neta" },
        { text: "Ejectivo CC", value: "ejecutivo" },
      ],
    };
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },

    consultar() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      var inicioUTC = null;
      var finUTC = null;

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.dates[1])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.dates[0])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        }
      }
      let query = {
        fechas: [inicioUTC, finUTC],
        aseguradora: 8,
      };

      this.$axios
        .post("v1/bradescard/reportes/fallidas", query, config)
        .then((value) => {
          this.reportDownloadIt = [];
          this.leads = [];
          value.data.map((element) => {
            this.leads.push({
              uuid: element.uuid,
              polizaInterna: element.polizaInterna
                ? element.polizaInterna
                : "No disponible",
              cliente:
                (element.nombre ? element.nombre : " ") +
                " " +
                (element.apellido_paterno ? element.apellido_paterno : " ") +
                " " +
                (element.apellido_materno ? element.apellido_materno : " "),
              fecha_inicio_vigencia: element.inicio
                ? moment.utc(element.inicio).format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              fecha_fin_vigencia: element.fin
                ? moment.utc(element.fin).format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              tipo_contratante: element.tipo_contratanteE
                ? element.tipo_contratanteE
                : "No disponible",
              plan: element.etiqueta_aseguradora
                ? element.etiqueta_aseguradora
                : "No disponible",
              suma_asegurada: element.suma_asegurada
                ? this.toCurrency(element.suma_asegurada)
                : "No disponible",
              prima_neta: element.prima_neta
                ? this.toCurrency(element.prima_neta)
                : "No disponible",
              ejecutivo: element.ejecutivo
                ? element.ejecutivo
                : "No disponible",
            });

            this.reportCompleteDowload.push({
              "Póliza Aseguradora": element.certificado
                ? element.certificado
                : "No disponible",
              "Póliza ID Interna": element.polizaInterna
                ? element.polizaInterna
                : "No disponible",
              "Estatus de la Póliza": element.status
                ? element.status == 0
                  ? "Nueva"
                  : element.status == 1
                  ? "Emitida Pendente de Pago"
                  : element.status == 2
                  ? "Pagada Parcial"
                  : element.status == 3
                  ? "Pagada Total"
                  : element.status == 4
                  ? "Renovada"
                  : element.status == 5
                  ? "Finalizada"
                  : element.status == 6
                  ? "Cancelada"
                  : element.status == 8
                  ? "Emitido el certificado"
                  : "Cancelada"
                : "No disponible",
              "Fecha de cancelacion": element.fechaCancelacion
                ? moment.utc(element.fechaCancelacion).format("DD/MM/YYYY")
                : "No Disponible",
              "Motivo cancelacion": element.motivoCancelacion
                ? element.motivoCancelacion
                : "No Disponible",
              Certificado: element.certificado
                ? element.certificado
                : "No disponible",
              Cliente:
                (element.nombre ? element.nombre : " ") +
                " " +
                (element.apellido_paterno ? element.apellido_paterno : " ") +
                " " +
                (element.apellido_materno ? element.apellido_materno : " "),
              "Nombre de Producto": element.producto
                ? element.producto
                : "No disponible",
              Ramo: element.ramo ? element.ramo : "No disponible",
              Aseguradora: element.aseguradora
                ? element.aseguradora
                : "No disponible",
              "Nueva/Renovacion": element.tipo_poliza
                ? element.tipo_poliza
                : "No disponible",
              "Inicio de Vigencia": element.inicio
                ? moment.utc(element.inicio).format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              "Fin de Vigencia": element.fin
                ? moment.utc(element.fin).format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              "Periodicidad de Pago": "Mensual",
              "Número de Cuotas": element.anios
                ? element.anios * 12
                : "No tiene",
              "Monto de la Póliza": element.montoPoliza
                ? element.montoPoliza
                : "No tiene",
              Años: element.anios ? element.anios : "No tiene",
              "Forma de Pago": element.forma_Pago
                ? element.forma_Pago == 1
                  ? "TC Bradescard"
                  : element.forma_Pago == 2
                  ? "Tarjeta de Débito"
                  : element.forma_Pago == 3
                  ? "Depósito en banco"
                  : element.forma_Pago == 4
                  ? "Transferencia"
                  : element.forma_Pago == 5
                  ? "Cheque"
                  : element.forma_Pago == 6
                  ? "Efectivo"
                  : element.forma_Pago == 7
                  ? "Domiciliación"
                  : element.forma_Pago == 8
                  ? "Cargo único"
                  : "Cancelada"
                : "No disponible",
              "Fecha primer pago": element.fecha_primer_pago
                ? moment
                    .utc(element.fecha_primer_pago)
                    .format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              Ejecutivo: element.ejecutivo
                ? element.ejecutivo
                : "No disponible",
              "Tipo de Validacion": element.tipoValidacion || "No disponible",
            });
            let jsonFormat = JSON.parse(element.request_json);
            this.reportDownloadIt.push({
              tipo_mov: element.status
                ? element.status == 0
                  ? "Nueva"
                  : element.status == 1
                  ? "Emitida Pendente de Pago"
                  : element.status == 2
                  ? "Pagada Parcial"
                  : element.status == 3
                  ? "Pagada Total"
                  : element.status == 4
                  ? "Renovada"
                  : element.status == 5
                  ? "Finalizada"
                  : element.status == 6
                  ? "Cancelada"
                  : element.status == 8
                  ? "Emitido el certificado"
                  : "Cancelada"
                : "Cancelada",
              Póliza: element.certificado
                ? element.certificado
                : "No disponible",
              "Inicio de inicio Vigencia": element.inicio
                ? moment.utc(element.inicio).format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              "Fin de fin Vigencia": element.fin
                ? moment.utc(element.fin).format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              "Nueva/Renovacion": element.tipo_poliza
                ? element.tipo_poliza
                : "No disponible",
              Moneda: "0",
              "Tipo contratante": element.tipo_contratanteE
                ? element.tipo_contratanteE
                : "No disponible",
              Plan: element.etiqueta_aseguradora
                ? element.etiqueta_aseguradora
                : "No disponible",
              "Suma aseguradora": element.suma_asegurada
                ? this.toCurrency(element.suma_asegurada)
                : "No disponible",
              "Prima neta": element.prima_neta
                ? this.toCurrency(element.prima_neta)
                : "No disponible",
              "Apellido Paterno": element.apellido_paterno
                ? element.apellido_paterno
                : "No disponible",
              "Apellido Materno": element.apellido_materno
                ? element.apellido_materno
                : "No disponible",
              "Nombre(s)": element.nombre ? element.nombre : "No disponible",
              Genero: element.genero ? element.genero : "No disponible",
              "Estado Civil": element.estado_civil
                ? element.estado_civil
                : "No disponible",
              RFC: element.rfc ? element.rfc : "No disponible",

              Calle: element.calleL
                ? element.calleL
                : element.calleE || "No disponible",
              "No. Exterior": element.num_exteriorL
                ? element.num_exteriorL
                : element.num_exteriorE || "No disponible",
              "No. Interior": element.num_interiorL
                ? element.num_interiorL
                : element.num_interiorE || "No disponible",
              Colonia: element.coloniaL
                ? element.coloniaL
                : element.coloniaE || "No disponible",
              "Codigo Postal": element.codigo_postalL
                ? element.codigo_postalL
                : element.codigo_postalE || "No disponible",
              Pais: element.paisL ? element.paisL : element.paisE || "Mexico",
              Estado: element.estadoL
                ? element.estadoL
                : this.getNombreEstado(jsonFormat.estado_id) || "No disponible",
              Municipio: element.municipioL
                ? element.municipioL
                : jsonFormat.municipio || "No disponible",

              "Calle emisión": element.calleE
                ? element.calleE
                : "No disponible",
              "No. Exterior emisión": element.num_exteriorE
                ? element.num_exteriorE
                : "No disponible",
              "No. Interior emisión": element.num_interiorE
                ? element.num_interiorE
                : "No disponible",
              "Colonia emisión": element.coloniaE
                ? element.coloniaE
                : "No disponible",
              "Codigo Postal emisión": element.codigo_postalE
                ? element.codigo_postalE
                : "No disponible",
              "Pais emisión": element.paisE ? element.paisE : "Mexico",
              "Estado emisión": this.getNombreEstado(jsonFormat.estado_id),

              "Municipio emisión": jsonFormat.municipio
                ? jsonFormat.municipio
                : "No disponible",
              "Año de construcción": element.anio_construccionE
                ? element.anio_construccionE
                : "No disponible",
              "Tipo de contrucción": element.tipo_construccionE
                ? element.tipo_construccionE
                : "No disponible",
              "Número de piso": element.num_pisosE
                ? element.num_pisosE
                : "No disponible",
              "Piso en el que vive": element.piso_viveE
                ? element.piso_viveE
                : "No disponible",
              Telefono: element.telefono_contacto
                ? element.telefono_contacto
                : "No disponible",
              "Correo electronico": element.correo_contacto
                ? element.correo_contacto
                : "No disponible",

              "Tipo de personas": element.tipo_persona
                ? element.tipo_persona
                : "No disponible",
              Periodicidad: "Mensual",
              "Forma de Pago": element.forma_Pago
                ? element.forma_Pago == 1
                  ? "TC Bradescard"
                  : element.forma_Pago == 2
                  ? "Tarjeta de Débito"
                  : element.forma_Pago == 3
                  ? "Depósito en banco"
                  : element.forma_Pago == 4
                  ? "Transferencia"
                  : element.forma_Pago == 5
                  ? "Cheque"
                  : element.forma_Pago == 6
                  ? "Efectivo"
                  : element.forma_Pago == 7
                  ? "Domiciliación"
                  : element.forma_Pago == 8
                  ? "Cargo único"
                  : "Cancelada"
                : "No disponible",
            });
            this.bloquear = false;
          });
        });
    },

    getNombreEstado(estado_id) {
      let nombreEstado = estado_id
        ? estado_id == 1
          ? "Aguascalientes"
          : estado_id == 2
          ? "Baja California"
          : estado_id == 3
          ? "Baja California Sur"
          : estado_id == 4
          ? "Campeche"
          : estado_id == 5
          ? "Coahuila de Zaragoza"
          : estado_id == 6
          ? "Colima"
          : estado_id == 7
          ? "Chiapas"
          : estado_id == 8
          ? "Chihuahua"
          : estado_id == 9
          ? "Ciudad de México"
          : estado_id == 10
          ? "Durango"
          : estado_id == 11
          ? "Guanajuato"
          : estado_id == 12
          ? "Guerrero"
          : estado_id == 13
          ? "Hidalgo"
          : estado_id == 14
          ? "Jalisco"
          : estado_id == 15
          ? "Estado de México"
          : estado_id == 16
          ? "Michoacán de Ocampo"
          : estado_id == 17
          ? "Morelos"
          : estado_id == 18
          ? "Nayarit"
          : estado_id == 19
          ? "Nuevo León"
          : estado_id == 20
          ? "Oaxaca"
          : estado_id == 21
          ? "Puebla"
          : estado_id == 22
          ? "Querétaro"
          : estado_id == 23
          ? "Quintana Roo"
          : estado_id == 24
          ? "San Luis Potosí"
          : estado_id == 25
          ? "Sinaloa"
          : estado_id == 26
          ? "Sonora"
          : estado_id == 27
          ? "Tabasco"
          : estado_id == 28
          ? "Tamaulipas"
          : estado_id == 29
          ? "Tlaxcala"
          : estado_id == 30
          ? "Veracruz de Ignacio de la Llave"
          : estado_id == 31
          ? "Yucatán"
          : estado_id == 32
          ? "Zacatecas"
          : "No disponible"
        : "No disponible";

      return nombreEstado;
    },

    newEmision() {
      this.$router.push("/administrador/carga-emisiones");
    },
    toCurrency(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number); /* $2,500.00 */
    },
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
  computed: {
    blockBoton() {
      return this.canal != null && this.dates != ["", ""];
    },
    canShow() {
      var canView = false;
      var roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = ["ADMIN", "DIRECTOR", "FINANZAS"];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
    currentRol() {
      let roles = [];
      this.roles.forEach((element) => {
        if (element.rol != null) {
          roles.push(element.rol);
        }
      });

      if (roles.length > 0) {
        return roles[0];
      } else {
        return "";
      }
    },
  },
};
</script>
