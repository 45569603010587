var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mb-15 fill-height"},[_c('v-col',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("Reporte Emisiones Fallidas Hogar")])])],1),_c('v-row',{staticStyle:{"padding-left":"30px","margin-top":"25px"}}),_c('v-row',{staticClass:"mb-15",staticStyle:{"padding-left":"30px"}},[_c('v-col',{attrs:{"md":"3"}},[_c('h3',[_vm._v("Selecciona el rango de fechas")]),_c('v-date-picker',{staticStyle:{"width":"100%"},attrs:{"range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}}),_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"rounded":"","color":"primary","dark":""},on:{"click":_vm.consultar}},[_vm._v("Consultar")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.currentRol == 'ADMIN' ||
              _vm.currentRol == 'ADMINCC' ||
              _vm.currentRol == 'AUDITOR' ||
              _vm.currentRol == 'MANAGERCC'
          ),expression:"\n            currentRol == 'ADMIN' ||\n              currentRol == 'ADMINCC' ||\n              currentRol == 'AUDITOR' ||\n              currentRol == 'MANAGERCC'\n          "}],staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"rounded":"","color":"primary","disabled":_vm.bloquear}},[_c('download-csv',{attrs:{"data":_vm.reportDownloadIt,"name":"Informe_bradescard.csv"}},[_vm._v(" Descargar Informe TI ")])],1)],1),_c('v-col',{attrs:{"cols":"9","md":"9"}},[_c('div',[(_vm.loading)?_c('div',{staticStyle:{"text-align":"center"}},[_c('h2',{attrs:{"clas":"pb-4"}},[_vm._v("Cargando Reporte....")]),_c('br'),_c('br'),_c('md-progress-spinner',{attrs:{"md-diameter":100,"md-stroke":10,"md-mode":"indeterminate"}})],1):_vm._e()]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.columns,"items":_vm.leads,"item-key":"name","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.urlPoliza",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"href":'https://segurify-data.s3.amazonaws.com/' + item.urlPoliza}},[_vm._v("Descargar")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }